import React, {useState, useEffect} from 'react';
import { InputBase, Menu, MenuItem, Divider, Dialog, DialogContent, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import localization from '../utils/localization';
import CustomStyleButton from '../components/CustomButton';
import Chains, {ChainConfig} from '../utils/chains';
import ChainNetworks from './ChainNetworks';

interface SearchInputProps {
  chainConfig?: ChainConfig;
  onEnter: (value: string, chainConfig?: ChainConfig) => void;
  defaultValue?: string;
}

export function SearchInput(props: SearchInputProps) {
  const theme = useTheme();
  const [borderColor, setBorderColor] = useState(theme.custom?.searchInputBorderColor)
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)

  // chains
  const [chainConfigs, setChainConfigs] = useState([] as ChainConfig[])
  useEffect(() => {
    setChainConfigs(Chains.getChains())
  }, [])
  const [chainConfig, setChainConfig] = useState(props.chainConfig)

  // menu
  const anchorRef = React.useRef(null);
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false)
  };

  // dialog
  const [chainNetworksOpen, setChainNetworksOpen] = useState(false);

  // search
  const [question, setQuestion] = React.useState(props.defaultValue || '');

  useEffect(() => {
    if (focus) {
      setBorderColor(theme.custom?.searchInputBorderFocusColor)
    } else if (hover) {
      setBorderColor(theme.custom?.searchInputBorderHoverColor)
    } else {
      setBorderColor(theme.custom?.searchInputBorderColor)
    }
  }, [hover, focus])

  return (
    <div style={{
      borderRadius: '0.8rem',
      border: `0.15rem solid ${borderColor}`,
    }}
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}>
      <InputBase
        placeholder={localization.strings.getString('searchInputPlaceholder')}
        style={{
          width: '100%',
          height: '3.6rem',
          borderRadius: '0.4rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          fontSize: '1rem',
        }}
        onFocus={(e) => setFocus(true)}
        onBlur={(e) => setFocus(false)}
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            props.onEnter(question, chainConfig)
          }
        }}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 0.8rem 0.4rem',
      }}>
        <div ref={anchorRef}>
          <CustomStyleButton icon={chainConfig? null : "icon-blockchain1"} img={chainConfig && chainConfig.logo} iconsize="1.1rem" radius='10rem' text={chainConfig ? chainConfig.name : localization.strings.getString('allChains')} fontsize='0.9rem' padding='0.6rem 0.9rem'
            onClick={handleClick}/>
        </div>
        <div style={{flex:1}}></div>
        <Menu anchorEl={anchorRef.current} open={open} onClose={handleClose} style={{
          width: '320px',
        }}>
          <MenuItem onClick={() => {
            handleClose()
            setChainConfig(undefined)
          }}>
            <div style={{
              width: '2.5rem',
              height: '1.3rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1rem 0'
            }}>
              <i className="iconfont icon-blockchain1" style={{
                fontSize: '1.3rem',
                display: 'block',
                color: theme.custom?.textColor,
              }}></i>
            </div>
            <div style={{
              fontSize: '0.9rem',
              color: theme.custom?.textColor,
              minWidth: '180px',
            }}>{localization.strings.getString('allChains')}</div>
          </MenuItem>
          {
            chainConfigs.map((item) => (
              <MenuItem key={item.chain_id} onClick={() => {
                handleClose()
                setChainConfig(item)
              }}>
                <div style={{
                  width: '2.5rem',
                  height: '1.3rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '1rem 0',
                }}>
                  <img src={item.logo} style={{
                    display: 'inline-block',
                    width: '1.5rem',
                    height: '1.5rem',
                    borderRadius: '50%',
                  }}/>
                </div>
                <div style={{
                  fontSize: '0.9rem',
                  color: theme.custom?.textColor,
                }}>{item.name}</div>
              </MenuItem>
            ))
          }
          <Divider />
          <MenuItem onClick={() => {
            handleClose()
            setChainNetworksOpen(true)
          }}>
            <div style={{
              width: '2.5rem',
              height: '1.3rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1rem 0'
            }}>
              <i className="iconfont icon-Setting" style={{
                fontSize: '1.3rem',
                display: 'block',
                color: theme.custom?.textColor,
              }}></i>
            </div>
            <div style={{
              fontSize: '0.9rem',
              color: theme.custom?.textColor,
              minWidth: '180px',
            }}>{localization.strings.getString('manageNetworks')}</div>
          </MenuItem>
        </Menu>
        <CustomStyleButton icon="icon-close" radius='10rem' onClick={() => {
          setQuestion('')
        }}/>
      </div>
      <ChainNetworks open={chainNetworksOpen} onClose={() => {
        setChainNetworksOpen(false)
        let chainConfigs = Chains.getChains()
        setChainConfigs(chainConfigs)
        if (chainConfig && chainConfigs.filter((item) => item.chain_id===chainConfig?.chain_id&&item.namespace===chainConfig?.namespace).length <= 0) {
          setChainConfig(undefined)
        }
      }}/>
    </div>
  )
}

export default SearchInput;