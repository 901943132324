import React, {useState, useEffect} from 'react';
import './App.css';
import {Routes, Route} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import toast, { Toaster } from 'react-hot-toast';
import Index from './routers/Index'
import Search from './routers/Search'
import LocalStorage from './utils/localStorage'

const darkModeLocalKey = 'dark_mode'

declare module '@mui/material/styles' {
  interface Theme {
    custom?: {
      backgroundColor: string;
      cardBackgroundColor: string;
      buttonHoverColor: string;
      buttonRippleColor: string;
      textColor: string;
      textGreyColor: string;
      searchInputBorderColor: string;
      searchInputBorderHoverColor: string;
      searchInputBorderFocusColor: string;
      green100: string;
      green200: string;
      green300: string;
      red100: string;
      red200: string;
      red300: string;
      deepGrey: string;
    };
  }
  interface ThemeOptions {
    custom?: {
      backgroundColor: string;
      cardBackgroundColor: string;
      buttonHoverColor: string;
      buttonRippleColor: string;
      textColor: string;
      textGreyColor: string;
      searchInputBorderColor: string;
      searchInputBorderHoverColor: string;
      searchInputBorderFocusColor: string;
      green100: string;
      green200: string;
      green300: string;
      red100: string;
      red200: string;
      red300: string;
      deepGrey: string;
    };
  }
}

function App() {
  let initDarkMode = false
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    initDarkMode = true
  }
  let darkModeLocal = LocalStorage.getItem(darkModeLocalKey)
  switch (darkModeLocal) {
    case 'false':
      initDarkMode = false
      break
    case 'true':
      initDarkMode = true
      break
  }
  const [darkMode, setDarkMode] = useState(initDarkMode);

  function getTheme(darkMode: boolean) {
    return createTheme({
      palette: {
        mode: darkMode ? 'dark' : 'light',
        primary: {
          main: '#2962FF',
        },
        success: {
          main: '#00C853',
        },
        error: {
          main: '#FF3D00',
        },
      },
      custom: {
        backgroundColor: darkMode?'#212121':'#FFFFFF',
        cardBackgroundColor: darkMode?'#212121':'#FAFAFA',
        buttonHoverColor: darkMode?'#424242':'#F5F5F5',
        buttonRippleColor: '#757575',
        textColor: darkMode?'#FAFAFA':'#212121',
        textGreyColor: darkMode?'#9E9E9E':'#757575',
        searchInputBorderColor: darkMode?'#424242':'#EEEEEE',
        searchInputBorderHoverColor: '#9E9E9E',
        searchInputBorderFocusColor: darkMode?'#EEEEEE':'#212121',
        green100: '#E8F5E9',
        green200: '#A5D6A7',
        green300: '#81C784',
        red100: '#FFCCBC',
        red200: '#FFAB91',
        red300: '#FF8A65',
        deepGrey: '#757575',
      },
    })
  }

  const [currentTheme, setCurrentTheme] = useState(getTheme(darkMode));

  useEffect(() => {
    setCurrentTheme(getTheme(darkMode))
    LocalStorage.setItem(darkModeLocalKey, `${darkMode}`)
  }, [darkMode]);

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline/>
      <Routes>
        <Route path="/" element={<Index darkMode={darkMode} toggleDarkMode={() => {
          setDarkMode(!darkMode)
        }}/>}/>
        <Route path="/search/:chain/:question" element={<Search darkMode={darkMode} toggleDarkMode={() => {
          setDarkMode(!darkMode)
        }}/>}/>
      </Routes>
      <Toaster/>
    </ThemeProvider>
  );
}

export default App;
