import LocalizedStrings from 'react-localization';

function getUrlVars(): Map<string, string> {
  let vars = new Map<string, string>();
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key: string, value: string) {
    vars.set(key, value)
    return ''
  });
  return vars;
}

function getUrlParam(parameter: string, defaultValue: string): string {
  let urlParameter: string | undefined = defaultValue;
  if(window.location.href.indexOf(parameter) > -1){
    urlParameter = getUrlVars().get(parameter);
  }
  return urlParameter || '';
}
var lang = getUrlParam("lang", '')

if (lang === "") {
  var localLang = navigator.language;
  if (typeof localLang === "string" && localLang.indexOf("zh") >= 0) {
    lang = "zh"
  }
} else {
  if (lang === 'cn') {
    lang = "zh"
  }
}

let strings = new LocalizedStrings({
  en:{
    'searchInputPlaceholder': 'Please input transaction hash',
    'auto': 'Auto',
    'manageNetworks': 'Manage Networks',
    'allChains': 'All Chains',
    'chainName': 'Chain Name',
    'chainLogo': 'Chain Logo',
    'chainID': 'Chain ID',
    'chainSymbol': 'Chain Symbol',
    'chainDecimals': 'Chain Decimals',
    'chainRpcUrl': 'Chain RPC URL',
    'delete': 'Delete',
    'cancel': 'Cancel',
    'confirm': 'Confirm',
    'addChain': 'Add Chain',
    'alertDialog': 'Alert Dialog',
    'deleteConfirm': 'Are you sure to delete this chain?',
    'willDelete': 'Will delete',
    'required': 'Required',
    'operationSucceeded': 'Operation Succeeded',
    'operationFailed': 'Operation failed',
    'exists': 'Already exists',
    'language': 'Language',
    'searchResult': 'Search Result',
    'invalidValue': 'Invalid value',
    'transactionHashTip': 'A transaction hash, also known as a transaction ID or tx hash, is a unique identifier that serves as proof that a transaction has been validated and added to a blockchain.',
    'transactionStatusTip': 'The status of the transaction',
    'failure': 'Failure',
    'success': 'Success',
    'blockNumberTip': 'Number of the block in which the transaction is recorded.',
    'timestampTip': 'The date and time at which a transaction is produced.',
    'fromTip': 'The sending party of the transaction.',
    'toTip': 'The receiving party of the transaction (could be a contract address).',
    'valueTip': 'The value being transacted.',
    'inputDataTip': 'Additional data included for this transaction. Commonly used as part of contract interaction or as a message sent to the recipient.',
    'tokenTransfer': 'Token Transfer',
    'tokenTransferTip': 'List of tokens transferred in the transaction.',
    'allTransfers': 'All Transfers',
    'netTransfers': 'Net Transfers',
  },
  zh: {
    'searchInputPlaceholder': '请输入交易哈希',
    'auto': '自动',
    'manageNetworks': '管理网络',
    'allChains': '所有链',
    'chainName': '链名称',
    'chainLogo': '链Logo',
    'chainID': '链ID',
    'chainSymbol': '链Symbol',
    'chainDecimals': '链Decimals',
    'chainRpcUrl': '链RPC链接',
    'delete': '删除',
    'cancel': '取消',
    'confirm': '确认',
    'addChain': '添加链',
    'alertDialog': '告警对话框',
    'deleteConfirm': '确定删除该链吗？',
    'willDelete': '将删除',
    'required': '必填项',
    'operationSucceeded': '操作成功',
    'operationFailed': '操作失败',
    'exists': '已存在',
    'language': '语言',
    'searchResult': '搜索结果',
    'invalidValue': '无效值',
    'transactionHashTip': '交易哈希（Transaction Hash）是一种唯一标识特定交易的标识符。',
    'transactionStatusTip': '交易状态',
    'failure': '失败',
    'success': '成功',
    'blockNumberTip': '交易记录在区块链中的区块编号',
    'timestampTip': '交易产生的日期和时间。',
    'fromTip': '交易的发送方。',
    'toTip': '交易的接收方，可以是一个合约地址',
    'valueTip': '交易价值',
    'inputDataTip': '此交易中包含的额外数据。通常作为合同互动的一部分，或作为发送给收件人的消息。',
    'tokenTransfer': '代币转账',
    'tokenTransferTip': '交易中转移的代币列表。',
    'allTransfers': '所有转账',
    'netTransfers': '净转账',
  },
});

strings.setLanguage(lang)

const langDescMap = new Map<string, string>([
    ["en", "English"],
    ["zh", "中文"]
]);

export default {
  strings: strings,
  language: {
    lang: lang,
    langDesc: langDescMap.get(lang) || '',
  },
};