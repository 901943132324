import React, {useState, useEffect} from 'react';
import { Box, Tabs, Tab, TextField, Button, Divider, Dialog, DialogContent, IconButton } from '@mui/material';
import Chains, {ChainConfig, ChainType} from '../utils/chains';
import localization from '../utils/localization';
import DialogAlert from './DialogAlert';
import toast, { Toaster } from 'react-hot-toast';
import CustomButton from './CustomButton';
import { useTheme } from '@mui/material/styles';

interface ChainNetworksProps {
  open: boolean;
  onClose: () => void;
}

export default function ChainNetworks(props: ChainNetworksProps) {
  const theme = useTheme();
  const [chainNameError, setChainNameError] = React.useState('');
  const [chainIDError, setChainIDError] = React.useState('');
  const [chainSymbolError, setChainSymbolError] = React.useState('');
  const [chainDecimalsError, setChainDecimalsError] = React.useState('');
  const [chainRpcUrlError, setChainRpcUrlError] = React.useState('');
  const [chainRpcUrl, setChainRpcUrl] = React.useState('');

  const [chainConfigs, setChainConfigs] = useState(Chains.getChains())
  const [chainConfig, setChainConfig] = useState(Object.assign({}, chainConfigs[0]))
  const [activeChainIndex, setActiveChainIndex] = useState(0)

  useEffect(() => {
    if (activeChainIndex < 0) {
      setChainConfig({
        default: false,
        namespace: '',
        chain_id: '',
        chain_type: ChainType.Ethereum,
        name: '',
        default_rpc_url_amount: 0,
        rpc_urls: [],
        logo: '',
        symbol: '',
        decimals: 18,
      })
    } else {
      setChainConfig(Object.assign({}, chainConfigs[activeChainIndex]))
    }
  }, [chainConfigs, activeChainIndex])

  useEffect(() => {
    setChainNameError('')
    setChainIDError('')
    setChainSymbolError('')
    setChainDecimalsError('')
    setChainRpcUrlError('')
    setChainRpcUrl('')
  }, [activeChainIndex])

  // alert dialog
  const [alertDialogOpen, setAlertDialogOpen] = useState(false)

  // tab
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setActiveChainIndex(newValue)
  }

  function doClose() {
    setChainConfigs(Chains.getChains())
    setActiveChainIndex(0)
    setTabValue(0)
    props.onClose()
  }
  
  return (
    <Dialog open={props.open} onClose={doClose}>
      <DialogContent style={{
        padding: '0',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          padding: '0rem 1.2rem',
        }}>
          <div style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            padding: '1rem 0',
            flex: 1,
          }}>
          {localization.strings.getString('manageNetworks')}
          </div>
          <Button variant="outlined" color="secondary" onClick={() => {
            setActiveChainIndex(-1)
          }}>{localization.strings.getString('addChain')}</Button>
        </div>
        <Divider/>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleTabChange}
            sx={{ borderRight: 1, borderColor: 'divider', textTransform: "none" }}>
            {
              Chains.getChains().map((chain) => {
                return (
                  <Tab key={chain.chain_id} label={chain.name} sx={{textTransform: 'none'}}/>
                )
              })
            }
          </Tabs>
          <div style={{
            minWidth: '30rem',
            padding: '1.5rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
          }}>
            <TextField id="outlined-basic" error={chainNameError!=''} helperText={chainNameError} label={localization.strings.getString('chainName')} variant="outlined" size="small" fullWidth required value={chainConfig.name} onChange={(event) => {
              setChainNameError('')
              setChainConfig(Object.assign({}, chainConfig, {name: event.target.value}))
            }} disabled={chainConfig.default}/>
            <TextField id="outlined-basic" label={localization.strings.getString('chainLogo')} variant="outlined" size="small" fullWidth style={{
              marginTop: '1rem',
            }} value={chainConfig.logo} onChange={(event) => {
              setChainConfig(Object.assign({}, chainConfig, {logo: event.target.value}))
            }} disabled={chainConfig.default}/>
            <TextField id="outlined-basic" error={chainIDError!=''} helperText={chainIDError} label={localization.strings.getString('chainID')} variant="outlined" size="small" fullWidth style={{
              marginTop: '1rem',
            }} required value={chainConfig.chain_id} onChange={(event) => {
              setChainIDError('')
              setChainConfig(Object.assign({}, chainConfig, {chain_id: event.target.value}))
            }} disabled={chainConfig.default}/>
            <TextField id="outlined-basic" error={chainSymbolError!=''} helperText={chainSymbolError} label={localization.strings.getString('chainSymbol')} placeholder='ETH' variant="outlined" size="small" fullWidth style={{
              marginTop: '1rem',
            }} required value={chainConfig.symbol} onChange={(event) => {
              setChainSymbolError('')
              setChainConfig(Object.assign({}, chainConfig, {symbol: event.target.value}))
            }} disabled={chainConfig.default}/>
            <TextField id="outlined-basic" type="number" error={chainSymbolError!=''} helperText={chainSymbolError} label={localization.strings.getString('chainDecimals')} placeholder='18' variant="outlined" size="small" fullWidth style={{
              marginTop: '1rem',
            }} required value={chainConfig.decimals} onChange={(event) => {
              setChainDecimalsError('')
              setChainConfig(Object.assign({}, chainConfig, {decimals: parseInt(event.target.value)}))
            }} disabled={chainConfig.default}/>
            {
              chainConfig.rpc_urls.map((item, index) => {
                return (
                  <div key={index} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                    <div style={{
                      flex: 1,
                    }}>
                      <TextField id="outlined-basic" disabled label={localization.strings.getString('chainRpcUrl')} variant="outlined" size="small" fullWidth style={{
                        marginTop: '1rem',
                      }} required value={chainConfig.rpc_urls[index]} onChange={(event) => {
                        setChainRpcUrlError('')
                        let rpc_urls = chainConfig.rpc_urls
                        rpc_urls[index] = event.target.value
                        setChainConfig(Object.assign({}, chainConfig, {rpc_urls: rpc_urls}))
                      }}/>
                    </div>
                    {
                      index >= chainConfig.default_rpc_url_amount && (
                        <div style={{
                          margin: '1rem 0rem 0rem 1rem',
                        }}>
                          <CustomButton icon="icon-close" mode="error" textColor={theme.palette.error.main} onClick={() => {
                            let rpc_urls = chainConfig.rpc_urls
                            rpc_urls.splice(index, 1)
                            setChainConfig(Object.assign({}, chainConfig, {rpc_urls: rpc_urls}))
                          }}/>
                        </div>
                      )
                    }
                  </div>
                )
              })
            }
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}>
              <div style={{
                flex: 1,
              }}>
                <TextField id="outlined-basic" error={chainRpcUrlError!=''} helperText={chainRpcUrlError} label={localization.strings.getString('chainRpcUrl')} variant="outlined" size="small" fullWidth style={{
                  marginTop: '1rem',
                }} required value={chainRpcUrl} onChange={(event) => {
                  setChainRpcUrlError('')
                  setChainRpcUrl(event.target.value)
                }}/>
              </div>
              <div style={{
                margin: '1rem 0rem 0rem 1rem',
              }}>
                <CustomButton icon="icon-add" mode="success" textColor={theme.palette.success.main} onClick={() => {
                  if (chainRpcUrl === '') {
                    return
                  }
                  if (chainConfig.rpc_urls.indexOf(chainRpcUrl) >= 0) {
                    setChainRpcUrlError(localization.strings.getString('exists'))
                    return
                  }
                  if (!chainRpcUrl.startsWith('http://') && !chainRpcUrl.startsWith('https://')) {
                    setChainRpcUrlError(localization.strings.getString('invalidValue'))
                    return
                  }
                  let rpc_urls = chainConfig.rpc_urls
                  rpc_urls.push(chainRpcUrl)
                  setChainConfig(Object.assign({}, chainConfig, {rpc_urls: rpc_urls}))
                }}/>
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '1rem 0 0rem',
              width: '100%',
            }}>
              <div style={{
                  display: 'block',
                  flex: 1,
                }}>
                {
                  !chainConfig.default && <Button fullWidth variant="outlined" color="error" onClick={() => {
                    setAlertDialogOpen(true)
                  }}>{localization.strings.getString('delete')}</Button>
                }
              </div>
              <div style={{width: '1rem'}}></div>
              <div style={{
                  display: 'block',
                  flex: 1,
                }}>
                <Button fullWidth variant="outlined" color="success" onClick={() => {
                  let hasError = false
                  if (chainConfig.name == '') {
                    setChainNameError(localization.strings.getString('required'))
                    hasError = true
                  }
                  if (chainConfig.chain_id == '') {
                    setChainIDError(localization.strings.getString('required'))
                    hasError = true
                  }
                  if (chainConfig.symbol == '') {
                    setChainSymbolError(localization.strings.getString('required'))
                    hasError = true
                  }
                  if (chainConfig.decimals <= 0) {
                    setChainRpcUrlError(localization.strings.getString('required'))
                    hasError = true
                  }
                  if (chainConfig.rpc_urls.length <= 0) {
                    setChainRpcUrlError(localization.strings.getString('required'))
                    hasError = true
                  }
                  if (activeChainIndex < 0) {
                    let hasExists = false
                    for (let item of chainConfigs) {
                      if (chainConfig.chain_id === item.chain_id && chainConfig.namespace === item.namespace) {
                        hasExists = true
                        break
                      }
                    }
                    if (hasExists) {
                      setChainIDError(localization.strings.getString('exists'))
                      hasError = true
                    }
                  }
                  if (hasError) {
                    return
                  }
                  Chains.saveChain(chainConfig)
                  toast.success(localization.strings.getString('operationSucceeded'));
                  doClose()
                }}>{localization.strings.getString('confirm')}</Button>
              </div>
            </div>
          </div>
          <DialogAlert title={localization.strings.getString('alertDialog')} content={localization.strings.deleteConfirm} hint={`${localization.strings.getString('willDelete')} "${chainConfig.name}"`} open={alertDialogOpen} onCancel={() => {
            setAlertDialogOpen(false)
          }} onConfirm={() => {
            setAlertDialogOpen(false)
            Chains.removeChain(chainConfig)
            toast.success(localization.strings.getString('operationSucceeded'));
            doClose()
          }}/>
        </Box>
      </DialogContent>
    </Dialog>
  )
}