import React, {useState, useEffect} from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import utilsWeb3 from '../utils/web3';
import chains, { ChainConfig } from '../utils/chains';
import { Box, Container, Menu, MenuItem, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../components/CustomButton';
import SearchInput from '../components/SearchInput';
import localization from '../utils/localization';
import Transaction from '../components/Transaction';
import svg404 from '../images/404.svg';
import svg500 from '../images/500.svg';

enum SearchStatus {
  Loading,
  Done,
  NotFound,
  Error,
}

interface SearchProps {
  darkMode: boolean;
  toggleDarkMode: () => void;
}

export default function Search(props: SearchProps) {
  let { chain, question } = useParams();
  const theme = useTheme();

  // loading
  const [searchStatus, setSearchStatus] = useState(SearchStatus.Loading)

  // language menu
  const anchorRefLanguage = React.useRef(null);
  const [openLanguage, setOpenLanguage] = useState(false);
  const handleClickLanguage = () => {
    setOpenLanguage(true)
  };
  const handleCloseLanguage = () => {
    setOpenLanguage(false)
  };

  // transaction
  let tempChainConfig: undefined | ChainConfig = undefined
  let chainConfigs = chains.getChains()
  for (let item of chainConfigs) {
    if (item.name === chain) {
      tempChainConfig = item
      break
    }
  }
  const [initChainConfig, setInitChainConfig] = useState<undefined | ChainConfig>(tempChainConfig)
  const [transaction, setTransaction] = useState({} as any)
  const [block, setBlock] = useState({} as any)
  const [resultChainConfig, setResultChainConfig] = useState<undefined | ChainConfig>(initChainConfig)

  async function searchQuestion(question: string, chainConfig?: ChainConfig) {
    setSearchStatus(SearchStatus.Loading)
    let chainConfigs: ChainConfig[] = []
    if (chainConfig) {
      chainConfigs.push(chainConfig)
    } else {
      chainConfigs = chains.getChainsByTxHash(question)
    }
    let results = await utilsWeb3.getTransaction(question, chainConfigs, async (chainConfig, txData) => {
      txData.receipt = await utilsWeb3.getReceiptOfChain(txData.hash, chainConfig)
      let blockData = await utilsWeb3.getBlockOfChain(txData.blockNumber, chainConfig)
      setResultChainConfig(chainConfig)
      setTransaction(txData)
      setBlock(blockData)
      setSearchStatus(SearchStatus.Done)
    })
    if (results.filter((item) => item).length <= 0) {
      if (results.filter((item) => item === null).length > 0) {
        setSearchStatus(SearchStatus.Error)
      } else {
        setSearchStatus(SearchStatus.NotFound)
      }
    }
  }

  useEffect(() => {
    if (!question) {
      return
    }
    searchQuestion(question, initChainConfig)
  }, [])

  return (
    <Box style={{
      backgroundColor: theme.custom?.backgroundColor,
      width: '100%',
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
    }}>
      <Container maxWidth="lg">
        <div style={{
          height: '3.6rem',
          margin: '0rem auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <a style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            color: theme.custom?.textColor,
            textDecoration: 'none',
          }} href="/">
            <div style={{
              marginRight: '0.5rem',
            }}>
              <i className="iconfont icon-blockchain1" style={{
                fontSize: '2rem',
                display: 'block',
                color: theme.custom?.textColor,
              }}></i>
            </div>
            <div style={{
              fontWeight: 'bold',
              fontSize: '1.2rem',
              color: theme.custom?.textColor,
            }}>Chain Explorer</div>
          </a>
          <div>
            <CustomButton icon={props.darkMode ? 'icon-icon-dark-mode-fill' : 'icon-LightMode'} iconsize='1.2rem'
              onClick={props.toggleDarkMode}/>
          </div>
          <div ref={anchorRefLanguage} style={{
            marginLeft: '1rem',
          }}>
            <CustomButton icon="icon-earth1" iconsize='1.2rem' onClick={handleClickLanguage}/>
          </div>
          <Menu anchorEl={anchorRefLanguage.current} open={openLanguage} onClose={handleCloseLanguage} style={{
            width: '320px',
          }}>
            <MenuItem onClick={() => {
              window.location.href = `/search/${chain}/${question}?lang=en`
            }}>English</MenuItem>
            <MenuItem onClick={() => {
              window.location.href = `/search/${chain}/${question}?lang=zh`
            }}>简体中文</MenuItem>
          </Menu>
        </div>
      </Container>
      <Container maxWidth="lg">
        <div style={{
          margin: '1rem auto 0.5rem',
        }}>
          <SearchInput chainConfig={initChainConfig} defaultValue={question} onEnter={(question, chainConfig) => {
            searchQuestion(question, chainConfig)
          }}/>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          margin: '0rem 0rem 1rem',
        }}>
          <i className="iconfont icon-target" style={{
            fontSize: '1.2rem',
            display: 'block',
            color: theme.custom?.textColor,
          }}></i>
          <div style={{
            fontSize: '1rem',
            display: 'block',
            color: theme.custom?.textColor,
            marginLeft: '0.4rem',
            padding: '0',
          }}>{localization.strings.getString('searchResult')}</div>
        </div>
        {
          searchStatus==SearchStatus.Loading && (
            <div>
              <Skeleton variant="rounded" width={'100%'} height={'8rem'} />
              <Skeleton variant="rounded" width={'100%'} height={'8rem'} style={{
                marginTop: '1rem',
              }}/>
            </div>
          )
        }
        {
          searchStatus==SearchStatus.Done && (
            <div style={{
              overflowY: 'scroll',
            }}>
              <Transaction chainConfig={resultChainConfig!} transaction={transaction} block={block}/>
            </div>
          )
        }
        {
          searchStatus==SearchStatus.NotFound && (
            <div style={{
              margin: '2rem 0rem',
            }}>
              <img src={svg404} style={{
                display: 'block',
                margin: '0rem auto',
                width: '30rem',
              }}/>
            </div>
          )
        }
        {
          searchStatus==SearchStatus.Error && (
            <div style={{
              margin: '2rem 0rem',
            }}>
              <img src={svg500} style={{
                display: 'block',
                margin: '0rem auto',
                width: '15rem',
              }}/>
            </div>
          )
        }
      </Container>
    </Box>
  )
}