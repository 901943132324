import React, {useState, useEffect} from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import chains, {ChainConfig} from '../utils/chains';
import utilsString from '../utils/string';

interface AddressProps {
  chainConfig: ChainConfig;
  address: string;
  symbol?: string;
}

export default function Address(props: AddressProps) {
  return (
    <Button variant="text" sx={{textTransform: 'none'}} onClick={() => {
      if (!props.chainConfig.chain_explorer) return
      window.open(`${utilsString.formatExplorerUrl(props.chainConfig.chain_explorer)}/address/${props.address}`, '_blank');
    }}>
      {
        props.symbol ? 
        (<span>{props.symbol}({props.address.substring(0, 8)}...{props.address.substring(props.address.length-8)})</span>)
        :
        (<span>{props.address.substring(0, 8)}...{props.address.substring(props.address.length-8)}</span>)
      }
    </Button>
  )
}