import chains, { ChainConfig } from './chains';

export interface TokenTransfer {
  token: string;
  from: string;
  to: string;
  id?: number;
  value: number;
}

export interface TokenNetTransfer {
  token: string;
  id?: number;
  value: number;
}

export interface AddressNetTransfer {
  address: string;
  net_transfers: TokenNetTransfer[];
}

enum TokenType {
  None = 'None',
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
}

export interface TokenInfo {
  caip2: string;
  address: string;
  token_type: TokenType;
  symbol: string;
  decimals: number;
}

let tokenInfoStorage = new Map<string, TokenInfo>();

async function getTransactionOfChain(hash: string, chainConfig: ChainConfig, callback: (chainConfig: ChainConfig, data: any) => void) {
  let hasResp = false;
  for (let rpc_url of chainConfig.rpc_urls) {
    let controller = new AbortController()
    setTimeout(() => {
      controller.abort()
    }, 10000)
    try {
      let respJson = await fetch(rpc_url, {
        method: 'POST',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          "jsonrpc": "2.0",
          "id": 96,
          "method": "eth_getTransactionByHash",
          "params": [hash]
        }),
        signal: controller.signal,
      }).then(response => response.json());
      hasResp = true
      if (respJson.result) {
        callback(chainConfig, respJson.result)
        return respJson.result;
      }
    } catch (e) {
      console.log(e);
    }
  }
  if (!hasResp) return null
  return undefined;
}

function parseTokenTransfer(log: any): TokenTransfer | undefined {
  if (log.topics.length>=3 && log.topics[0]==='0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef') {
    let token = log.address;
    let from = '0x' + log.topics[1].substr(26);
    let to = '0x' + log.topics[2].substr(26);
    let id: number | undefined = undefined;
    let value = 0;
    if (log.topics.length===3) {
      value = parseInt(log.data);
    } else if (log.topics.length===4) {
      id = parseInt(log.topics[3]);
      value = 1;
    }
    return {token, from, to, id, value};
  } else if (log.topics.length>=3 && log.topics[0]==='0xc3d58168c5ae7397731d063d5bbf3d657854427343f4c083240f7aacaa2d0f62') {
    let token = log.address;
    let from = '0x' + log.topics[1].substr(26);
    let to = '0x' + log.topics[2].substr(26);
    let id = parseInt('0x'+log.data.substr(2, 64));
    let value = parseInt('0x'+log.data.substr(66, 64));
    return {token, from, to, id, value};
  }
}

export default {
  getTransaction: async function (hash: string, chainConfigs: ChainConfig[], callback: (chainConfig: ChainConfig, data: any) => void) {
    let promises = chainConfigs.map(chainConfig => getTransactionOfChain(hash, chainConfig, callback));
    return await Promise.all(promises);
  },
  getReceiptOfChain: async function(hash: string, chainConfig: ChainConfig) {
    for (let rpc_url of chainConfig.rpc_urls) {
      let controller = new AbortController()
      setTimeout(() => {
        controller.abort()
      }, 5000)
      try {
        let respJson = await fetch(rpc_url, {
          method: 'POST',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            "jsonrpc": "2.0",
            "id": 96,
            "method": "eth_getTransactionReceipt",
            "params": [hash]
          }),
          signal: controller.signal,
        }).then(response => response.json());
        if (respJson.result) {
          return respJson.result;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  getBlockOfChain: async function(blockNumber: string, chainConfig: ChainConfig) {
    for (let rpc_url of chainConfig.rpc_urls) {
      let controller = new AbortController()
      setTimeout(() => {
        controller.abort()
      }, 5000)
      try {
        let respJson = await fetch(rpc_url, {
          method: 'POST',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            "jsonrpc": "2.0",
            "id": 96,
            "method": "eth_getBlockByNumber",
            "params": [blockNumber, false]
          }),
          signal: controller.signal,
        }).then(response => response.json());
        if (respJson.result) {
          return respJson.result;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  filterTokenTransfer: function(logs: any) {
    return logs.filter((log: any) => {
      return log.topics.length>0 && ['0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef', 
        '0xc3d58168c5ae7397731d063d5bbf3d657854427343f4c083240f7aacaa2d0f62'].includes(log.topics[0]);
    })
  },
  parseTokenTransfer: parseTokenTransfer,
  parseNetTransfer: function(logs: any): AddressNetTransfer[] {
    let ret: AddressNetTransfer[] = [];
    for (let log of logs) {
      let tokenTransfer = parseTokenTransfer(log);
      if (!tokenTransfer) continue;

      // from address
      let fromNetTransfer = ret.find((item) => item.address === tokenTransfer!.from);
      if (!fromNetTransfer) {
        fromNetTransfer = {address: tokenTransfer.from, net_transfers: []};
        ret.push(fromNetTransfer);
      }
      let fromTokenNetTransfer = fromNetTransfer.net_transfers.find((item) => item.token === tokenTransfer!.token && item.id === tokenTransfer!.id);
      if (!fromTokenNetTransfer) {
        fromTokenNetTransfer = {token: tokenTransfer.token, id: tokenTransfer.id, value: 0};
        fromNetTransfer.net_transfers.push(fromTokenNetTransfer);
      }
      fromTokenNetTransfer.value -= tokenTransfer.value;

      // to address
      let toNetTransfer = ret.find((item) => item.address === tokenTransfer!.to);
      if (!toNetTransfer) {
        toNetTransfer = {address: tokenTransfer.to, net_transfers: []};
        ret.push(toNetTransfer);
      }
      let toTokenNetTransfer = toNetTransfer.net_transfers.find((item) => item.token === tokenTransfer!.token && item.id === tokenTransfer!.id);
      if (!toTokenNetTransfer) {
        toTokenNetTransfer = {token: tokenTransfer.token, id: tokenTransfer.id, value: 0};
        toNetTransfer.net_transfers.push(toTokenNetTransfer);
      }
      toTokenNetTransfer.value += tokenTransfer.value;
    }
    return ret
  },
  getTokenInfo: async function(address: string, chainConfig: ChainConfig, transferLog: any): Promise<TokenInfo> {
    let tokenInfo: TokenInfo = {
      caip2: `${chainConfig.namespace}:${chainConfig.chain_id}`,
      address: address,
      token_type: TokenType.None,
      symbol: '',
      decimals: 0,
    }
    if (tokenInfoStorage.has(`${tokenInfo.caip2}/${tokenInfo.address}`)) {
      return tokenInfoStorage.get(`${tokenInfo.caip2}/${tokenInfo.address}`)!;
    }
    if (transferLog.topics.length===3) {
      tokenInfo.token_type = TokenType.ERC20;
    } else if (transferLog.topics.length===4) {
      tokenInfo.token_type = TokenType.ERC721;
    }
    let controller = new AbortController()
    setTimeout(() => {
      controller.abort()
    }, 10000)
    for (let rpc_url of chainConfig.rpc_urls) {
      try {
        let respJson = await fetch(rpc_url, {
          method: 'POST',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            "jsonrpc": "2.0",
            "id": 96,
            "method": "eth_call",
            "params": [{
              "from": null,
              "to": address,
              "data": "0x95d89b41",
            }, "latest"],
          }),
          signal: controller.signal,
        }).then(response => response.json());
        if (respJson.result) {
          let length = parseInt('0x'+respJson.result.substr(66, 64), 16);
          let symbol = respJson.result.substr(130, length*2);
          tokenInfo.symbol = decodeURIComponent('%' + symbol.match(/.{1,2}/g).join('%'))
        }
        if (transferLog.topics.length == 3 && transferLog.topics[0]==='0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef') {
          respJson = await fetch(rpc_url, {
            method: 'POST',
            headers: {
              'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({
              "jsonrpc": "2.0",
              "id": 96,
              "method": "eth_call",
              "params": [{
                "from": null,
                "to": address,
                "data": "0x313ce567",
              }, "latest"],
            }),
            signal: controller.signal,
          }).then(response => response.json());
          tokenInfo.decimals = parseInt('0x'+respJson.result.substr(2, 64), 16);
        }
        tokenInfoStorage.set(`${tokenInfo.caip2}/${tokenInfo.address}`, tokenInfo);
      } catch (e) {
        console.log(e);
      }
    }
    return tokenInfo
  }
}