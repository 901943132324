import React, {useState, useEffect} from 'react';
import { Container, Box, FormControl, InputLabel, Select, OutlinedInput, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../components/CustomButton';
import SearchInput from '../components/SearchInput';
import { useNavigate, Outlet, useLocation } from "react-router-dom";

interface IndexProps {
  darkMode: boolean;
  toggleDarkMode: () => void;
}

export function Index(props: IndexProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  // language menu
  const anchorRefLanguage = React.useRef(null);
  const [openLanguage, setOpenLanguage] = useState(false);
  const handleClickLanguage = () => {
    setOpenLanguage(true)
  };
  const handleCloseLanguage = () => {
    setOpenLanguage(false)
  };

  return (
    <Box style={{
      backgroundColor: theme.custom?.backgroundColor,
      width: '100%',
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}>
      <Container maxWidth="lg">
        <div style={{
          height: '3.6rem',
          margin: '0rem auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '35%',
          minWidth: '50rem',
          maxWidth: '80rem',
        }}>
          <div style={{flex: 1}}></div>
          <div>
            <CustomButton icon={props.darkMode ? 'icon-icon-dark-mode-fill' : 'icon-LightMode'} iconsize='1.2rem'
              onClick={props.toggleDarkMode}/>
          </div>
          <div ref={anchorRefLanguage} style={{
            marginLeft: '1rem',
          }}>
            <CustomButton icon="icon-earth1" iconsize='1rem' onClick={handleClickLanguage}/>
          </div>
          <Menu anchorEl={anchorRefLanguage.current} open={openLanguage} onClose={handleCloseLanguage} style={{
            width: '320px',
          }}>
            <MenuItem onClick={() => {
              window.location.href = '?lang=en'
            }}>English</MenuItem>
            <MenuItem onClick={() => {
              window.location.href = '?lang=zh'
            }}>简体中文</MenuItem>
          </Menu>
        </div>
      </Container>
      <div style={{
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          color: theme.custom?.textColor,
        }}>
          <div style={{
            marginRight: '0.5rem',
          }}>
            <i className="iconfont icon-blockchain1" style={{
              fontSize: '2.4rem',
              display: 'block',
              color: theme.custom?.textColor,
            }}></i>
          </div>
          <div style={{
            fontWeight: 'bold',
            fontSize: '1.8rem',
            color: theme.custom?.textColor,
          }}>Chain Explorer</div>
        </div>
        <div style={{
          fontWeight: 'bold',
          fontSize: '1.1rem',
          color: theme.custom?.textColor,
        }}>All-in-one on-chain data explorer</div>
        <div style={{
          margin: '1rem auto',
          width: '35%',
          minWidth: '45rem',
          maxWidth: '80rem',
        }}>
          <SearchInput onEnter={(question, chainConfig) => {
            navigate(`/search/${chainConfig ? chainConfig.name : 'all'}/${question}`)
          }}/>
        </div>
        <div style={{ height: '8rem' }}></div>
      </div>
    </Box>
  )
}

export default Index;