import React, {useState, useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles'

interface CustomButtonProps {
  radius?: string;
  padding?: string;
  onClick?: () => void;
  icon?: string | null;
  iconsize?: string;
  textColor?: string;
  img?: string | null;
  text?: string;
  fontsize?: string;
  mode?: 'default' | 'success' | 'error';
}

export function CustomButton(props: CustomButtonProps) {
  const theme = useTheme();

  let backgroundColor: string | undefined = 'transparent'
  switch (props.mode) {
    case 'success':
      backgroundColor = theme.custom?.green100
      break;
    case 'error':
      backgroundColor = theme.custom?.red100
      break;
    default:
      break;
  }

  let hoverColor = theme.custom?.buttonHoverColor
  switch (props.mode) {
    case 'success':
      hoverColor = theme.custom?.green100
      break;
    case 'error':
      hoverColor = theme.custom?.red100
      break;
    default:
      break;
  }
  let rippleColor = theme.custom?.buttonRippleColor
  switch (props.mode) {
    case 'success':
      rippleColor = theme.custom?.green300
      break;
    case 'error':
      rippleColor = theme.custom?.red300
      break;
    default:
      break;
  }

  const CustomStyleButton = styled(ButtonBase)({
    borderRadius: '0.2rem',
    '&:hover': {
      backgroundColor: hoverColor,
    },
    "&:click": {
      backgroundColor: "blue"
    },
    '.MuiTouchRipple-child': {
      backgroundColor: rippleColor,
    }
  });

  return (
    <CustomStyleButton style={{
      borderRadius: props.radius || '0.4rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: props.padding || '0.6rem',
    }} sx={{
      backgroundColor: backgroundColor,
    }} onClick={props.onClick}>
        {
          props.icon && <i className={`iconfont ${props.icon}`} style={{
            fontSize: props.iconsize || '1.2rem',
            display: 'block',
            color: props.textColor || theme.custom?.textColor,
          }}></i>
        }
        {
          props.img && <img src={props.img} style={{
            display: 'inline-block',
            width: props.iconsize,
            height: props.iconsize,
            borderRadius: '50%',
          }}/>
        }
        {props.text && 
          <div style={{
            fontSize: props.fontsize || '1rem',
            display: 'block',
            color: props.textColor || theme.custom?.textColor,
            marginLeft: '0.4rem',
            padding: '0',
          }}>
            {props.text}
          </div>
        }
    </CustomStyleButton>
  )
}

export default CustomButton;