import React, {useState, useEffect} from 'react';
import { Tooltip } from '@mui/material';
import localization from '../utils/localization';
import { useTheme } from '@mui/material/styles';

interface CustomIconProps {
  icon: string;
  iconsize: string;
  tip: string;
}

export default function CustomIcon(props: CustomIconProps) {
  const theme = useTheme();

  return (
    <Tooltip title={props.tip} placement="top">
      <i className={`iconfont ${props.icon}`} style={{
        fontSize: props.iconsize || '1.2rem',
        color: theme.custom?.textColor,
      }}></i>
    </Tooltip>
  )
}