import React, {useState, useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import localization from '../utils/localization';

interface StatusProps {
  result: 'failure' | 'success'
}

export default function Status(props: StatusProps) {
  const theme = useTheme();

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      border: `0.05rem solid ${{'failure':theme.palette.error.main,'success':theme.palette.success.main}[props.result]}`,
      borderRadius: '0.3rem',
      backgroundColor: {'failure':theme.custom?.red100,'success':theme.custom?.green100}[props.result],
      padding: '0.1rem 0.5rem',
    }}>
      <div style={{
        marginRight:'0.3rem',
      }}>
        <i className={`iconfont ${{'failure':'icon-fail','success':'icon-check'}[props.result]}`} style={{
          fontSize: '0.9rem',
          color: {'failure':theme.palette.error.main,'success':theme.palette.success.main}[props.result],
        }}></i>
      </div>
      <div style={{
        fontSize: '0.7rem',
        fontWeight: 'bold',
        color: {'failure':theme.palette.error.main,'success':theme.palette.success.main}[props.result],
      }}>{{'failure':localization.strings.getString('failure'),'success':localization.strings.getString('success')}[props.result]}</div>
    </div>
  )
}