import React, {useState, useEffect} from 'react';
import { Tooltip } from '@mui/material';
import localization from '../utils/localization';
import { useTheme } from '@mui/material/styles';
import CustomIcon from './CustomIcon';

interface FieldRowProps {
  key?: any;
  tips?: string;
  leftComponent: any;
  rightComponent: any;
  style?: any;
}

export default function FieldRow(props: FieldRowProps) {
  const theme = useTheme();

  return (
    <div key={props.key} style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
      ...props.style
    }}>
      <div style={{
        fontSize: '1rem',
        color: theme.custom?.textGreyColor,
        minWidth: '10rem',
        width: '30%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}>
        {
          props.tips && (
            <div style={{
              marginRight: '0.3rem',
            }}>
              <CustomIcon icon="icon-question" iconsize="1.2rem" tip={props.tips}/>
            </div>
          )
        }
        {props.leftComponent}
      </div>
      {props.rightComponent}
    </div>
  )
}