import LocalStorage from './localStorage'
import utilsString from '../utils/string';

const localKey = 'chains_config'

export enum ChainType {
  Ethereum = "Ethereum",
}

export interface ChainConfig {
  default: boolean;
  namespace: string;
  chain_id: string;
  chain_type: ChainType;
  name: string;
  default_rpc_url_amount: number;
  rpc_urls: string[];
  logo: string;
  symbol: string;
  decimals: number;
  chain_explorer?: string;
}

function getChains(): ChainConfig[] {
  let chains: ChainConfig[] = [
    {
      default: true,
      namespace: 'eip155',
      chain_id: '1',
      chain_type: ChainType.Ethereum,
      name: 'Ethereum',
      default_rpc_url_amount: 1,
      rpc_urls: ['https://eth.drpc.org'],
      logo: '/assets/ethereum.png',
      symbol: 'ETH',
      decimals: 18,
      chain_explorer: 'https://etherscan.io',
    },
    {
      default: true,
      namespace: 'eip155',
      chain_id: '10',
      chain_type: ChainType.Ethereum,
      name: 'Optimism',
      default_rpc_url_amount: 1,
      rpc_urls: ['https://optimism.drpc.org'],
      logo: '/assets/optimism.png',
      symbol: 'ETH',
      decimals: 18,
      chain_explorer: 'https://optimistic.etherscan.io',
    },
    {
      default: true,
      namespace: 'eip155',
      chain_id: '42161',
      chain_type: ChainType.Ethereum,
      name: 'Arbitrum',
      default_rpc_url_amount: 1,
      rpc_urls: ['https://arbitrum.drpc.org'],
      logo: '/assets/arbitrum.jpeg',
      symbol: 'ETH',
      decimals: 18,
      chain_explorer: 'https://arbiscan.io',
    },
    {
      default: true,
      namespace: 'eip155',
      chain_id: '56',
      chain_type: ChainType.Ethereum,
      name: 'Binance Smart Chain',
      default_rpc_url_amount: 1,
      rpc_urls: ['https://bsc.publicnode.com'],
      logo: '/assets/bnb.png',
      symbol: 'BNB',
      decimals: 18,
      chain_explorer: 'https://bscscan.com',
    },
    {
      default: true,
      namespace: 'eip155',
      chain_id: '43114',
      chain_type: ChainType.Ethereum,
      name: 'Avalanche C-Chain',
      default_rpc_url_amount: 1,
      rpc_urls: ['https://avalanche.drpc.org'],
      logo: '/assets/avalanche.png',
      symbol: 'AVAX',
      decimals: 18,
      chain_explorer: 'https://avascan.info/blockchain/c',
    },
    {
      default: true,
      namespace: 'eip155',
      chain_id: '137',
      chain_type: ChainType.Ethereum,
      name: 'Polygon',
      default_rpc_url_amount: 1,
      rpc_urls: ['https://polygon.drpc.org'],
      logo: '/assets/polygon.png',
      symbol: 'MATIC',
      decimals: 18,
      chain_explorer: 'https://polygonscan.com',
    },
    {
      default: true,
      namespace: 'eip155',
      chain_id: '8453',
      chain_type: ChainType.Ethereum,
      name: 'Base',
      default_rpc_url_amount: 1,
      rpc_urls: ['https://base.publicnode.com'],
      logo: '/assets/base.png',
      symbol: 'ETH',
      decimals: 18,
      chain_explorer: 'https://basescan.org',
    },
    {
      default: true,
      namespace: 'eip155',
      chain_id: '169',
      chain_type: ChainType.Ethereum,
      name: 'Manta',
      default_rpc_url_amount: 1,
      rpc_urls: ['https://pacific-rpc.manta.network/http'],
      logo: '/assets/manta.jpg',
      symbol: 'ETH',
      decimals: 18,
      chain_explorer: 'https://manta.socialscan.io',
    },
    {
      default: true,
      namespace: 'eip155',
      chain_id: '324',
      chain_type: ChainType.Ethereum,
      name: 'zkSync Era',
      default_rpc_url_amount: 1,
      rpc_urls: ['https://mainnet.era.zksync.io'],
      logo: '/assets/zksync-era.png',
      symbol: 'ETH',
      decimals: 18,
      chain_explorer: 'https://explorer.zksync.io',
    },
  ]
  let chainsLocalStr = LocalStorage.getItem(localKey)
  let chainsLocal = chainsLocalStr ? JSON.parse(chainsLocalStr) : []
  if (chainsLocal.length > 0) {
    for (let chainItem of chainsLocal) {
      let isExist = false
      for (let i=0;i<chains.length;i++) {
        if (chains[i].chain_id === chainItem.chain_id && chains[i].namespace === chainItem.namespace) {
          for (let rpc_url of chainItem.rpc_urls) {
            if (chains[i].rpc_urls.indexOf(rpc_url) < 0) {
              chains[i].rpc_urls.push(rpc_url)
            }
          }
          isExist = true
          break
        }
      }
      if (!isExist) {
        chains.push(chainItem)
      }
    }
  }
  return chains
}

function saveLocalStorage(chains: ChainConfig[]) {
  localStorage.setItem(localKey, JSON.stringify(chains))
}

export default {
  getChains: getChains,
  saveChain: (chainConfig: ChainConfig) => {
    let chains = getChains()
    let isExist = false
    for (let i=0;i<chains.length;i++) {
      if (chains[i].chain_id === chainConfig.chain_id && chains[i].namespace === chainConfig.namespace) {
        if (chains[i].default) {
          chains[i].rpc_urls = chainConfig.rpc_urls
        } else {
          chains[i] = chainConfig
        }
        isExist = true
        break
      }
    }
    if (!isExist) {
      chains.push(chainConfig)
    }
    saveLocalStorage(chains)
  },
  removeChain: (chainConfig: ChainConfig) => {
    let chains = getChains()
    for (let i=0;i<chains.length;i++) {
      if (chains[i].chain_id === chainConfig.chain_id && chains[i].namespace === chainConfig.namespace && !chains[i].default) {
        chains.splice(i, 1)
        saveLocalStorage(chains)
        return
      }
    }
  },
  getChainType: (chainConfig: ChainConfig): ChainType => {
    switch (chainConfig.chain_type) {
    case ChainType.Ethereum:
      return ChainType.Ethereum
    default:
      return ChainType.Ethereum
    }
  },
  getChainsByTxHash: (hash: string): ChainConfig[] => {
    let chains = getChains()
    if (hash.length === 66 && hash.startsWith('0x')) {
      return chains.filter(chain => chain.chain_type === ChainType.Ethereum)
    }
    return []
  },
  getTxExplorer: (chainConfig: ChainConfig, hash: string): string => {
    if (!chainConfig.chain_explorer) return ''
    return `${utilsString.formatExplorerUrl(chainConfig.chain_explorer!)}/tx/${hash}`
  },
}