import React, {useState, useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import localization from '../utils/localization';
import CustomIcon from './CustomIcon';
import CustomButton from '../components/CustomButton';
import FieldRow from './FieldRow';
import chains, {ChainConfig} from '../utils/chains';
import Status from '../components/Status'
import moment from 'moment';
import utilsWeb3, {AddressNetTransfer, TokenTransfer, TokenInfo} from '../utils/web3';
import Address from './Address';

interface TransactionProps {
  chainConfig: ChainConfig
  transaction: any;
  block: any;
}

export default function Transaction(props: TransactionProps) {
  const theme = useTheme();

  const [copyIcon, setCopyIcon] = useState('icon-cc-copy')
  const [transfer, setTransfer] = useState('all')

  const [tokenInfoDict, setTokenInfoDict] = useState(new Map<string, TokenInfo>())

  async function updateTokenInfo() {
    if (!props.transaction) return
    let td = new Map<string, TokenInfo>();
    for (let log of utilsWeb3.filterTokenTransfer(props.transaction.receipt.logs)) {
      let tokenInfo = await utilsWeb3.getTokenInfo(log.address, props.chainConfig, log)
      td.set(tokenInfo.address, tokenInfo)
    }
    console.log(td)
    setTokenInfoDict(td)
  }

  useEffect(() => {
    updateTokenInfo()
  }, [props.transaction])

  return (
    <div style={{
      paddingBottom: '1rem',
    }}>
      {
        props.chainConfig && (
          <div>
            <Card variant="outlined" style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <CardContent style={{
                width: '100%',
                backgroundColor: theme.custom?.cardBackgroundColor,
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'start',
                  margin: '0rem 0rem 1rem',
                }}>
                  <img src={props.chainConfig.logo} style={{
                    display: 'block',
                    width: '1.5rem',
                    height: '1.5rem',
                    borderRadius: '50%',
                    margin: '0rem 0.5rem 0rem 0rem',
                  }}/>
                  <div style={{
                    fontSize: '1rem',
                    color: theme.custom?.textColor,
                    fontWeight: 'bold',
                  }}>{props.chainConfig.name}</div>
                </div>
                <FieldRow tips={localization.strings.getString('transactionHashTip')} leftComponent={
                  <div style={{
                    fontSize: '1rem',
                    color: theme.custom?.textGreyColor,
                  }}>Transaction Hash:</div>
                } rightComponent={
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <div style={{
                      fontSize: '1rem',
                      color: theme.custom?.textColor,
                    }}>{props.transaction.hash}</div>
                    <div style={{
                      marginLeft: '0.3rem',
                    }}>
                      <CustomButton icon={copyIcon} textColor={copyIcon==='icon-cc-copy'?theme.custom?.textColor:theme.palette.success.main} iconsize="1.2rem" onClick={() => {
                        navigator.clipboard.writeText(props.transaction.hash)
                        setCopyIcon('icon-check')
                        setTimeout(() => {
                          setCopyIcon('icon-cc-copy')
                        }, 2000)
                      }}/>
                    </div>
                    <div style={{
                      marginLeft: '0.3rem',
                    }}>
                      {
                        props.chainConfig.chain_explorer && (
                          <CustomButton icon="icon-link-1" iconsize="1.2rem" onClick={() => {
                            window.open(chains.getTxExplorer(props.chainConfig!, props.transaction.hash), '_blank');
                          }}/>
                        )
                      }
                    </div>
                  </div>
                }/>
                <FieldRow tips={localization.strings.getString('transactionStatusTip')} leftComponent={
                  <div style={{
                    fontSize: '1rem',
                    color: theme.custom?.textGreyColor,
                  }}>Status:</div>
                } rightComponent={
                  <Status result={parseInt(props.transaction.receipt.status) == 1 ? 'success' : 'failure'} />
                } style={{
                  marginTop: '0.5rem',
                }}/>
                <FieldRow tips={localization.strings.getString('blockNumberTip')} leftComponent={
                  <div style={{
                    fontSize: '1rem',
                    color: theme.custom?.textGreyColor,
                  }}>Block Number:</div>
                } rightComponent={
                  <div>{parseInt(props.transaction.blockNumber)}</div>
                } style={{
                  marginTop: '0.5rem',
                }}/>
                <FieldRow tips={localization.strings.getString('timestampTip')} leftComponent={
                  <div style={{
                    fontSize: '1rem',
                    color: theme.custom?.textGreyColor,
                  }}>Timestamp:</div>
                } rightComponent={
                  <div>{moment.utc(new Date(parseInt(props.block.timestamp)*1000)).format('YYYY-MM-DD HH:mm:ss UTC')}</div>
                } style={{
                  marginTop: '0.5rem',
                }}/>
                <FieldRow tips={localization.strings.getString('fromTip')} leftComponent={
                  <div style={{
                    fontSize: '1rem',
                    color: theme.custom?.textGreyColor,
                  }}>From:</div>
                } rightComponent={
                  <div>{props.transaction.from}</div>
                } style={{
                  marginTop: '0.5rem',
                }}/>
                <FieldRow tips={localization.strings.getString('toTip')} leftComponent={
                  <div style={{
                    fontSize: '1rem',
                    color: theme.custom?.textGreyColor,
                  }}>To:</div>
                } rightComponent={
                  <div>{props.transaction.to}</div>
                } style={{
                  marginTop: '0.5rem',
                }}/>
                <FieldRow tips={localization.strings.getString('valueTip')} leftComponent={
                  <div style={{
                    fontSize: '1rem',
                    color: theme.custom?.textGreyColor,
                  }}>Value:</div>
                } rightComponent={
                  <div>{parseInt(props.transaction.value) / Math.pow(10, props.chainConfig.decimals)} {props.chainConfig.symbol}</div>
                } style={{
                  marginTop: '0.5rem',
                }}/>
                <FieldRow tips={localization.strings.getString('inputDataTip')} leftComponent={
                  <div style={{
                    fontSize: '1rem',
                    color: theme.custom?.textGreyColor,
                  }}>Input Data:</div>
                } rightComponent={
                  <div style={{
                    flex: 1,
                    overflow: 'hidden',
                  }}>
                    <TextField disabled value={props.transaction.input} size="small" fullWidth multiline rows="2" inputProps={{ style: { resize: "both" } }}/>
                  </div>
                } style={{
                  marginTop: '0.5rem',
                  alignItems: 'start',
                }}/>
              </CardContent>
            </Card>
            <div style={{height:'1rem'}}></div>
            {
              utilsWeb3.filterTokenTransfer(props.transaction.receipt.logs).length > 0 && (
                <Card variant="outlined" style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <CardContent style={{
                    width: '100%',
                    backgroundColor: theme.custom?.cardBackgroundColor,
                  }}>
                    <div style={{
                      color: theme.custom?.textGreyColor,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}>
                      <div style={{
                        marginRight: '0.3rem',
                      }}>
                        <CustomIcon icon="icon-question" iconsize="1.2rem" tip={localization.strings.getString('tokenTransferTip')}/>
                      </div>
                      <div style={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: theme.custom?.textColor,
                      }}>{localization.strings.getString('tokenTransfer')}</div>
                      <div style={{flex:1}}></div>
                      <ToggleButtonGroup
                        color="primary"
                        value={transfer}
                        exclusive
                        onChange={(event: any) => {
                          setTransfer(event.target.value)
                        }}
                        size="small">
                        <ToggleButton value="all" sx={{textTransform: 'none'}}>{localization.strings.getString('allTransfers')}</ToggleButton>
                        <ToggleButton value="net" sx={{textTransform: 'none'}}>{localization.strings.getString('netTransfers')}</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                    <div style={{
                      marginTop: '1rem',
                    }}>
                      {
                        transfer==='all' && utilsWeb3.filterTokenTransfer(props.transaction.receipt.logs).map((item: TokenTransfer, index: number) => {
                          let tokenTransfer = utilsWeb3.parseTokenTransfer(item)!
                          return (
                            <div key={index}>
                              From &nbsp;
                              <Address chainConfig={props.chainConfig} address={tokenTransfer.from}/> &nbsp;
                              To &nbsp;
                              <Address chainConfig={props.chainConfig} address={tokenTransfer.to}/> &nbsp;
                              For &nbsp;
                              {(tokenInfoDict.has(tokenTransfer.token) ? tokenTransfer?.value / Math.pow(10, tokenInfoDict.get(tokenTransfer.token)!.decimals) : tokenTransfer?.value).toLocaleString('en-US')} &nbsp;
                              of &nbsp;
                              { tokenTransfer.id!=undefined && (<span>TokenID[{tokenTransfer.id}]</span>) } &nbsp;
                              <Address chainConfig={props.chainConfig} address={tokenTransfer.token}
                                symbol={tokenInfoDict.has(tokenTransfer.token) ? tokenInfoDict.get(tokenTransfer.token)?.symbol : undefined}/> 
                            </div>
                          )
                        })
                      }
                      {
                        transfer==='net' && utilsWeb3.parseNetTransfer(utilsWeb3.filterTokenTransfer(props.transaction.receipt.logs)).map((item: AddressNetTransfer, index: number) => {
                          return (
                            <FieldRow key={item.address} leftComponent={
                              <div><Address chainConfig={props.chainConfig} address={item.address}/></div>
                            } rightComponent={
                              <div style={{
                                flex: 1,
                                overflow: 'hidden',
                              }}>
                                {
                                  item.net_transfers.map(item => {
                                    return (
                                      <div key={item.token}>
                                        {(tokenInfoDict.has(item.token) ? item.value / Math.pow(10, tokenInfoDict.get(item.token)!.decimals) : item.value).toLocaleString('en-US')} &nbsp;
                                        of &nbsp;
                                        { item.id!=undefined && (<span>TokenID[{item.id}]</span>) } &nbsp;
                                        <Address chainConfig={props.chainConfig} address={item.token}
                                          symbol={tokenInfoDict.has(item.token) ? tokenInfoDict.get(item.token)?.symbol : undefined}/>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            } style={{
                              marginTop: '0.5rem',
                              alignItems: 'start',
                            }}/>
                          )
                        })
                      }
                    </div>
                  </CardContent>
                </Card>
              )
            }
          </div>
        )
      }
    </div>
  )
}